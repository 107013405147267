import banner from './resources/images/banner.png';
import bannerAnnounce from './resources/images/banner-announce.png';
import ad1 from './resources/images/1.png';
import ad2 from './resources/images/2.png';
import ad3 from './resources/images/3.jpg';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import './App.css';
import moment from 'moment';

import { getPeriods, getStatisticToday, getStatistics } from './services/services';
import { AxiosResponse } from 'axios';

const beforeStartDownAnnounceDate = moment().format('YYYY-MM-DD 13:50:00');
const afterStartDownAnnounceDate = moment().format('YYYY-MM-DD 13:51:59');
const startDownAnnounceDate = moment().format('YYYY-MM-DD 13:52:00');
const endDownAnnouneDate = moment().format('YYYY-MM-DD 13:54:59');
const downAnnounceDate = moment().format('YYYY-MM-DD 13:55:00');

const beforeStartUpAnnounceDate = moment().format('YYYY-MM-DD 13:55:01');
const afterStartUpAnnounceDate = moment().format('YYYY-MM-DD 13:56:59');
const startUpAnnounceDate = moment().format('YYYY-MM-DD 13:57:00');
const endUpAnnouneDate = moment().format('YYYY-MM-DD 13:59:59');
const upAnnounceDate = moment().format('YYYY-MM-DD 14:00:00');

const randomNumber = () => {
  const rand = Math.floor(Math.random() * 10);
  // const two = Math.floor(Math.random() * 10);
  // const three = Math.floor(Math.random() * 10);
  // const four = Math.floor(Math.random() * 10);
  // const five = Math.floor(Math.random() * 10);

  return rand + "";
}

const getLoading = () => {
  return 'loading';
}

const imageLoading = () => {
  const images = [];
  for (let i = 0; i < 5; i++) {
    const image = <img
      style={{ marginRight: 2 }}
      src={require('./resources/images/loading.gif')}
      width={50}
      height={50}
      alt='number'
    />;

    images.push(image);
  }
  return images;
}

class App extends Component {
  _isMounted = false;
  timer: any = null;
  downTimer: any = null;
  upTimer: any = null;

  state = {
    isMobile: false,
    statistics: [],
    beforeStartDown: false,
    beforeStartUp: false,
    todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    announceStartDate: moment().format('YYYY-MM-DD 13:40:00'),
    announceEndDate: moment().format('YYYY-MM-DD 14:00:00'),
    onePrizes: {
      one: getLoading(),
      two: getLoading(),
      three: getLoading(),
      four: getLoading(),
      five: getLoading()
    },
    twoPrizes: {
      one: getLoading(),
      two: getLoading(),
      three: getLoading(),
      four: getLoading(),
      five: getLoading()
    },
    oldAnnounce: false,
    newAnnounce: false,
    downAnnounce: false,
    oldUpAnnounce: false,
    downRelease: false,
    upAnnounce: false,
  };

  componentWillUnmount(): void {
    this._isMounted = false;
    // clearInterval(this.timer);
    clearInterval(this.downTimer);
    clearInterval(this.upTimer);
    window.removeEventListener("resize", this.resizeWindow);
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.getAllStatistics();
    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();

    this.setTimer();
  }

  getAllStatistics = async () => {
    const response: AxiosResponse = await getStatistics();
    this.setState({
      statistics: response.data
    })
  }

  setTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
        this.announce();
      }, 1000);
    }
  }

  announce = () => {
    const { announceStartDate, announceEndDate, todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
      this.beforeAnnonce();
    } else if (
      moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
      && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
    ) {
      this.setData();
    } else {
      this.afterAnnounce();
    }
  }

  beforeAnnonce = async () => {
    const { todayDate, oldAnnounce } = this.state;
    if (!oldAnnounce) {
      const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 14:00:00");
      const period = await getPeriods(dateNow, 'both', '4');
      const up = period.data.up6;
      const down = period.data.down6;

      this.setState({
        onePrizes: {
          one: up[0],
          two: up[1],
          three: up[2],
          four: up[3],
          five: up[4],
        },
        twoPrizes: {
          one: down[0],
          two: down[1],
          three: down[2],
          four: down[3],
          five: down[4],
        },
        oldAnnounce: true
      })
    }
  }

  afterAnnounce = async () => {

    const { newAnnounce } = this.state;
    if (!newAnnounce) {
      const statisticToday = await getStatisticToday();

      const up = statisticToday.data.up6;
      const down = statisticToday.data.down6;

      this.setState({
        onePrizes: {
          one: up[0],
          two: up[1],
          three: up[2],
          four: up[3],
          five: up[4],
        },
        twoPrizes: {
          one: down[0],
          two: down[1],
          three: down[2],
          four: down[3],
          five: down[4],
        },
        newAnnounce: true
      })
    }

  }

  setData = async () => {
    const { todayDate, beforeStartDown, beforeStartUp, downAnnounce, oldUpAnnounce, downRelease, upAnnounce } = this.state;

    // --------------------- *** DOWN *** -------------------------
    // before start down
    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(afterStartDownAnnounceDate).toDate().getTime()) {
      if (!beforeStartDown) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          twoPrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          beforeStartDown: true
        })
      }
    }

    // down random number started
    if (moment(todayDate).toDate().getTime() >= moment(startDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(endDownAnnouneDate).toDate().getTime()) {
      if (!this.downTimer) {
        this.downTimer = setInterval(() => {
          this.setState({
            twoPrizes: {
              one: randomNumber(),
              two: randomNumber(),
              three: randomNumber(),
              four: randomNumber(),
              five: randomNumber(),
            }
          })
        }, 100);
      }
    }

    // down announce
    if (moment(todayDate).toDate().getTime() === moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // call api and show down
      if (!downAnnounce) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 14:00:00");
        const period = await getPeriods(dateNow, 'down', '4');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            one: down[0],
            two: down[1],
            three: down[2],
            four: down[3],
            five: down[4]
          },
          downAnnounce: true
        });
      }
    }

    if (moment(todayDate).toDate().getTime() > moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // show release down
      if (!downRelease) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 14:00:00");
        const period = await getPeriods(dateNow, 'down', '4');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            one: down[0],
            two: down[1],
            three: down[2],
            four: down[3],
            five: down[4]
          },
          downRelease: true
        });
      }
    }

    // --------------------- *** UP *** -------------------------
    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      // show old up
      if (!oldUpAnnounce) {
        const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 14:00:00");
        const period = await getPeriods(dateNow, 'up', '4');
        const up = period.data.up6;

        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          oldUpAnnounce: true
        });
      }

    }

    // before start up
    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading()
          },
          beforeStartUp: true
        })
      }
    }

    // up random number started
    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (!this.upTimer) {
        this.upTimer = setInterval(() => {
          this.setState({
            onePrizes: {
              one: randomNumber(),
              two: randomNumber(),
              three: randomNumber(),
              four: randomNumber(),
              five: randomNumber()
            }
          })
        }, 100);
      }
    }

    // up announce
    if (moment(todayDate).toDate().getTime() === moment(upAnnounceDate).toDate().getTime()) {
      if (this.upTimer) clearInterval(this.upTimer);

      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await getStatisticToday();

        const up = statisticToday.data.up6;
        const down = statisticToday.data.down6;

        this.setState({
          onePrizes: {
            one: up[0],
            two: up[1],
            three: up[2],
            four: up[3],
            five: up[4]
          },
          twoPrizes: {
            one: down[0],
            two: down[1],
            three: down[2],
            four: down[3],
            five: down[4]
          },
          upAnnounce: true
        })
      }
    }
  }

  renderDate = () => {
    const { todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime()) {
      return moment().format("DD/MM/YYYY");
    }

    return moment().add(-1, 'd').format("DD/MM/YYYY");
  }

  resizeWindow = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      this.setState({ isMobile: true });
    }
    else {
      this.setState({ isMobile: false });
    }
  }

  renderAnnounce = (prizes: any) => {

    if (prizes.one === 'loading') {
      return imageLoading();
    }

    return prizes.one + prizes.two + prizes.three + prizes.four + prizes.five;
  }

  render(): React.ReactNode {

    const { statistics, onePrizes, twoPrizes, isMobile } = this.state;
    return (
      <div className="container p-0">
        <div className="header d-flex justify-content-center">
          <img src={banner} className="img-fluid" height="250px" alt="hanoi" />
        </div>

        <div className="row pt-3">
          <div className="col-md-8 col-sm-12 col-xs-12">
            <div className="card d-flex justify-content-center mt-0 mb-2" style={{ border: 'none' }}>
              <div className="card-body p-0">
                {!isMobile ? <div className="d-flex flex-row">
                  <h1 className="mr-3 mb-0 align-self-center" style={{ color: 'red' }}>TRỰC TIẾP XỔ SỐ MIỀN BẮC</h1>
                  <h1 className="mt-0 mb-0 align-self-center" style={{ fontFamily: 'PSL', color: 'black', fontSize: 52, fontWeight: 'bold' }}>{this.renderDate()}</h1>
                </div> : <div className="d-flex justify-content-center">
                  <h5 className="mr-3 mb-0 align-self-center" style={{ color: 'red' }}>TRỰC TIẾP XỔ SỐ MIỀN BẮC</h5>
                  <h4 className="mt-0 mb-0 align-self-center" style={{ fontFamily: 'PSL', color: 'black', fontSize: 40, fontWeight: 'bold' }}>{this.renderDate()}</h4>
                </div>
                }
                <div className="text-center" style={{ backgroundColor: '#8b0404' }}>
                  <span style={{ fontFamily: 'PSL-LOGO', fontWeight: 'bolder', fontSize: '4rem' }} className="logo-gradient">HANOI EXPRESS</span>
                </div>
              </div>
            </div>

            <table className="table table-bordered mb-0">
              <tbody>
                <tr>
                  <td style={{ fontSize: 24, textAlign: 'center', width: 150, verticalAlign: 'middle', borderColor: '#B8B8B8' }}>
                    <span>Đặc biệt</span>
                  </td>
                  <td colSpan={2} style={{ padding: 0, textAlign: 'center', verticalAlign: 'middle', borderColor: '#B8B8B8' }}>
                    <span style={{ fontSize: 64, fontWeight: 1000, letterSpacing: 4, fontFamily: 'PSL', color: 'red' }}>{this.renderAnnounce(onePrizes)}</span>
                  </td>
                </tr>
                <tr style={{ backgroundColor: '#EBEBEB' }}>
                  <td style={{ fontSize: 24, textAlign: 'center', width: 150, verticalAlign: 'middle', borderColor: '#B8B8B8' }}>
                    <span>Giải nhất</span>
                  </td>
                  <td colSpan={2} style={{ padding: 0, textAlign: 'center', verticalAlign: 'middle', borderColor: '#B8B8B8' }}>
                    <span style={{ fontSize: 46, fontWeight: 1000, letterSpacing: 4, fontFamily: 'PSL' }}>{this.renderAnnounce(twoPrizes)}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-center mt-4">Kết quả xổ số trực tiếp trước đó</h3>
            <table className="table announce table-bordered table-striped m-0">
              <thead>
                <tr className="text-center">
                  <th>Ngày trao giải</th>
                  <th>Giải nhất</th>
                  <th>Giải nhì</th>
                </tr>
              </thead>
              <tbody style={{ fontFamily: 'PSL', fontSize: 30 }}>
                {
                  statistics?.length > 0 ? statistics.map((v: any, index: number) => {
                    return <tr key={index + v.dateAnnounce}>
                      <td>{moment(v.dateAnnounce).format("DD-MM-YYYY")}</td>
                      <td>{v.up3}</td>
                      <td>{v.down2}</td>
                    </tr>
                  }) : <tr>
                    <td colSpan={3} style={{ fontSize: 16 }}>Không có thông tin</td>
                  </tr>
                }
              </tbody>
            </table>

          </div>
          <div className="col-md-4 col-sm-12 col-xs-12 ">
            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0">
                <img src={ad1} width="100%" height="300px" alt="" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad2} width="100%" height="300px" alt="" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0" style={{ border: '1px solid gray', margin: 3 }}>
                <img src={ad3} width="100%" height="300px" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default App;
